<template>
  <div class="home row mt-1">

    <h3>文章列表</h3>

    <p class="mt-1" @click="$router.push(item.path)" v-for="(item, index) in list" :key="index">{{ item.title }}</p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      list: [
        { title: '使用Javascript实现一个Promise', path: '/bz4r' },
        { title: 'Proxy对象的使用方式', path: '/uw3s' },
        { title: 'Javascript中实现类继承的方式', path: '/hs1a' },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  font-size: 18px;
  cursor: pointer;
  padding: 24px 32px;
  box-shadow: 0px 16px 32px rgb(0 0 0 / 4%);
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: #fafafa;
  &:hover {
    color: #409EFF;
    border: 1px solid #409EFF;
  }
}
</style>
