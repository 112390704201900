<template>
  <header>
    <div class="row">
      <h2 @click="$router.push('/')"><img src="./assets/globe_asia.png">心灵沉思池</h2>
      <nav>
        <router-link to="/">首页</router-link> |
        <router-link to="/about">关于</router-link>
      </nav>
    </div>
  </header>

  <router-view />
</template>

<style lang="scss" scoped>
header .row {
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header {
  box-shadow: 0px 16px 32px rgb(0 0 0 / 4%);
}

header .row h2 {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  img {
    height: 36px;
  }
}
</style>
